<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('pmspatialtables.formtitle')">
					<v-layout wrap>
						<v-flex xs12 md6>
							<pui-text-field
								:label="$t('pmspatialtables.title')"
								v-model="model.name"
								toplabel
								required
								maxlength="45"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md6>
							<pui-select
								attach="pmspatialmodelsid"
								:label="this.$t('pmspatialtables.model')"
								:placeholder="this.$t('pmspatialtables.phmodel')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppmspatialmodels"
								:modelFormMapping="{ pmspatialmodelsid: 'pmspatialmodelsid' }"
								:itemsToSelect="modelItemsToSelect"
								itemValue="pmspatialmodelsid"
								itemText="name"
								:fixedFilter="spatialmodelFixedFilter"
							></pui-select>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md6>
							<pui-select
								v-if="isSuperAdmin"
								attach="pmorganizationid"
								:label="this.$t('pmspatialtables.organization')"
								:placeholder="this.$t('pmspatialtables.phorganization')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppmorganization"
								:modelFormMapping="{ pmorganizationid: 'pmorganizationid' }"
								:itemsToSelect="organizationItemsToSelect"
								itemValue="pmorganizationid"
								itemText="name"
							></pui-select>
							<pui-text-field
								v-else
								:label="$t('pmspatialtables.organization')"
								toplabel
								disabled
								required
								v-model="organizationName"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md6>
							<pui-select
								attach="pmdatasourcetypeid"
								:label="this.$t('pmspatialtables.pmdatasourcetype')"
								:placeholder="this.$t('pmspatialtables.phdatasourcetype')"
								toplabel
								clearable
								required
								reactive
								:disabled="formDisabled || this.model.pmorganizationid == null"
								v-model="model"
								modelName="vluppmdatasourcetype"
								:modelFormMapping="{ pmdatasourcetypeid: 'pmdatasourcetypeid' }"
								:itemsToSelect="datasourcetypeItemsToSelect"
								itemValue="pmdatasourcetypeid"
								itemText="name"
								:fixedFilter="pmdatasourcetypeFixedFilter"
								filterId="pmorganizationid"
								:filterMap="{ pmorganizationid: model.pmorganizationid }"
								:filterParentMap="{ pmorganizationid: 'id' }"
							></pui-select>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12>
							<pui-text-area
								v-model="model.description"
								:label="$t('pmspatialtables.description')"
								maxlength="250"
								toplabel
							></pui-text-area>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-checkbox
								:label="$t('pmspatialtables.visibility')"
								v-model="model.visibility"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
						<v-flex xs12 md4>
							<pui-checkbox
								:label="$t('pmspatialtables.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="createIdLayer"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

import constants from '@/utils/constants';
import { isSuperAdmin, search } from '../../api/common';

export default {
	name: 'pmspatialtablesform',
	mixins: [PuiFormMethodsMixin],

	data() {
		return {
			modelName: 'pmspatialtables',
			model: 'pmspatialtables',
			isSuperAdmin: false,
			pmdatasourcetypeFixedFilter: {},
			spatialmodelFixedFilter: {}
		};
	},
	watch: {
		'model.pmorganizationid': function (oldValue, newValue) {
			if (this.model.pmorganizationid == null || (oldValue != newValue && newValue != null)) {
				this.model.pmdatasourcetypeid = null;
			}
		}
	},
	computed: {
		getIdLayer() {
			return [{ idlayer: this.model.title.replace(/\s/g, '').toLowerCase() }];
		},
		modelItemsToSelect() {
			return [{ pmspatialmodelsid: this.model.pmspatialmodelsid }];
		},
		datasourcetypeItemsToSelect() {
			return [{ pmdatasourcetypeid: this.model.pmdatasourcetypeid }];
		},
		organizationItemsToSelect() {
			return [{ pmorganizationid: this.model.pmorganizationid }];
		},
		organizationName() {
			return this.userProperties['organizationname'];
		}
	},
	mounted() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
		let userProperties = this.userProperties;
		let rulesFilter = [
			{ field: 'model', op: 'eq', data: constants.DEVICES_MODEL },
			{ field: 'disabled', op: 'eq', data: 0 }
		];
		if (!this.isSuperAdmin) {
			this.pmdatasourcetypeFixedFilter = {
				groups: [],
				groupOp: 'and',
				rules: [
					{
						field: 'pmorganizationid',
						op: 'eq',
						data: userProperties.organizationid
					},
					{
						field: 'disabled',
						op: 'eq',
						data: 0
					}
				]
			};
		}
		console.log('debug rules filter', rulesFilter);
		this.spatialmodelFixedFilter = {
			groups: [],
			groupOp: 'or',
			rules: rulesFilter
		};
	},
	updated() {
		if (this.model) {
			if (!this.isSuperAdmin) {
				this.modelLoaded = true;

				this.model.pmorganizationid = this.userProperties['organizationid'];
			}
		}
	},
	methods: {
		async getModels() {
			var rulesAreas = [
				{
					field: 'model',
					op: 'eq',
					data: constants.NOTIFICATIONS_MODEL
				},
				{
					field: 'model',
					op: 'eq',
					data: constants.SATELLITE_MODEL
				},
				{
					field: 'model',
					op: 'eq',
					data: constants.DEVICES_MODEL
				}
			];
			const filterArea = {
				groups: [],
				groupOp: 'or',
				rules: rulesAreas
			};

			const body = {
				model: 'pmspatialmodels',
				filter: filterArea
			};

			const { data } = await search(body);
			console.log('debug RESULT spatial models', data.data);
		},
		createIdLayer() {
			let deviceType = this.$store.getters.getDatasourcetype.find(
				(datasourcetype) => datasourcetype.pmdatasourcetypeid == this.model.pmdatasourcetypeid
			);
			this.model.idlayer = deviceType
				? ('devices_' + deviceType['organizationacronym'] + '_' + deviceType['elasticacronym']).toLowerCase()
				: null;

			this.save();

			console.log('idLayer set: ', this.model.idlayer);
		}
	}
};
</script>
